import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./about.scss"
import Img from "gatsby-image" // add some style if you want!

export default function About({ data: { allImageContent } }: { data: any }) {
  const img = allImageContent.edges.find(
    (element: any) => element.node.fluid.originalName === "my-portrait.jpg"
  )

  return (
    <Layout>
      <SEO
        title="Super Productivity – To do list & time tracker for programmers & designers"
        description="Free to do list & time tracker for programmers & designers with build-in Jira & Github Integrations. Download for free or hack it for yourself."
      />

      <Img className="portrait" fluid={img.node.fluid} />

      <article className="container-small">
        <p>
          Hello! I am Johannes. I am the main author of this app. I have been
          working as a freelance web developer for well over a decade now. While
          initially programming was more of a part time thing to earn money, I
          can now say with confidence that I really do love my job.
        </p>
        <p>
          Super Productivity came to life when I grew annoyed by the
          repetitiveness and dullness of tracking my time to Jira Issues as
          required by some freelance project. Programmers do not like
          repetitiveness, as avoiding it tends to be a big part of the job.
          Sometimes I like to think that my natural laziness was the driving
          factor in becoming a qualified programmer in the first place and that
          building Super Productivity is the result of probably the most extreme
          case of productive procrastination you will ever encounter (:
        </p>
        <p>
          The initial prototype just took me a week to build. Back then I never
          would have thought that this would grew into a complex multi-language
          app with thousands of users and many wonderful people contributing to
          it in a variety of ways.
        </p>
        <p>
          Even though this is a non profit project I spend most of my free time
          on it. It gives me a lot of joy to use something I build myself
          everyday and to see that I have built something useful, not just for
          me, but many others, makes me appreciate this whole project even more.
        </p>
        <hr />

        <p>
          Please reach out if you have any questions, additional features you
          would like to suggest or any bugs you encounter.{" "}
          <strong>Super Productivity is a community project</strong> and{" "}
          <a href="https://github.com/johannesjo/super-productivity#hearts-contributing">
            there are lots of different ways to contribute
          </a>
          . Really, any kind of feedback is useful and welcome!
        </p>


        <p>If you want to donate a little something to support Super Productivity's development, you can do so in GitHub:</p>
        <iframe src="https://github.com/sponsors/johannesjo/card"
                className="git-sponsor-card"
                title="Sponsor me to contribute to Super Productivity!"
                height="125"
                width="600" />

        <address>
          <a href="mailto:contact@super-productivity.com" className="mail">
            contact@super-productivity.com
          </a>
          <a href="https://dev.to/johannesjo">dev.to profile</a>
          <a href="https://github.com/johannesjo">github profile</a>
          <a href="https://github.com/johannesjo/super-productivity/issues/new">
            suggest a feature
          </a>
          <a href="https://github.com/johannesjo/super-productivity/issues/new">
            report a bug
          </a>
          <a href="https://spectrum.chat/super-productivity">discuss</a>
        </address>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allImageContent: allImageSharp {
      edges {
        node {
          fluid(maxWidth: 250) {
            originalName
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  }
`
